import { ApolloLink, HttpLink, split } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { getMainDefinition, Observable } from '@apollo/client/utilities'
import { FeatureFlagNames } from 'constants/FeatureFlags'
import { ORGANISATION_ID } from 'constants/Memory'
import flagsmith from 'react-native-flagsmith'
import { sessionHeaders } from 'src/utils/errorTracking/sessionInfo'
import { omitDeep } from 'src/utils/omitDeep'
import { SyncStorage } from 'src/utils/SyncStorage'

import vetradarmobile from '../vetradar-exports'
import { getAccessToken } from './utils/getAccessToken'
import {
  graphQLErrorLog,
  sentryGraphQLErrorCapture,
} from './utils/graphQLLogging'
import { networkErrorHandler } from './utils/networkErrorHandler'
import { retryAuthGraphQL } from './utils/retryAuthGraphql'
import { sessionErrorToast } from './utils/sessionErrorToast'
import { wsLink } from './wsLink'
import { environment } from 'src/config'
import { ReleaseStage } from 'src/config/environment'
import { signOut } from 'src/utils/signOut'
import { reloadApp } from 'src/utils/reloadApp'
import { Auth } from 'src/context/auth'

const contextLink = setContext(async () => {
  try {
    const token = await getAccessToken()
    // 1. organisationId will be set once user select organisation right after login page.
    // 2. It will be empty string only when query getUserOrganisations but our back end API won't block this case
    const currentOrganisationId = SyncStorage.getItem(ORGANISATION_ID)
    return {
      headers: {
        authorization: token,
        'x-vr-organisation-id': currentOrganisationId,
        ...sessionHeaders,
      },
    }
  } catch (err) {
    if (err instanceof Error) sessionErrorToast(err)
    return signOut()
  }
})

const MAX_RETRIES = 10
let retryCount = 0

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      // eslint-disable-next-line no-restricted-syntax
      for (const graphQLError of graphQLErrors) {
        const authTokenRetryOperation = retryAuthGraphQL({
          graphQLError,
          operation,
          forward,
        })
        if (authTokenRetryOperation) {
          // If the error is due to unauthenticated, refresh the token and retry the operation
          if (graphQLError?.extensions?.code === 'UNAUTHENTICATED') {
            // eslint-disable-next-line @typescript-eslint/no-loop-func
            Auth.refreshToken().catch(() => {
              if (retryCount >= MAX_RETRIES) {
                console.log('Max retries exceeded. Signing out user.') // eslint-disable-line no-console
                signOut()
                return reloadApp()
              }
            })
          }
          // If the error is due to network or server error, retry the operation
          // eslint-disable-next-line no-plusplus
          retryCount++
          console.log(`Retrying with new Auth Token (Attempt ${retryCount})`) // eslint-disable-line no-console
          return authTokenRetryOperation // returning forwarded observable to retry the request
        }
        graphQLErrorLog(graphQLError, operation)
        sentryGraphQLErrorCapture(graphQLError, operation)
      }
    }

    if (networkError) {
      networkErrorHandler(networkError, operation)
    }
    // error-link should only return value if you want to retry the operation.
    // https://www.apollographql.com/docs/react/api/link/apollo-link-error/
    return
  },
)

// Create an http link:
const isLocalHost = vetradarmobile.graphqlEndpoint.indexOf('localhost') !== -1
const httpLink = new HttpLink({
  uri: operation =>
    `http${isLocalHost ? '' : 's'}://${vetradarmobile.graphqlEndpoint}${
      environment.isDev || environment.releaseStage === ReleaseStage.master
        ? `/${operation.operationName}`
        : ''
    }`,
})

const noopTerminatingLink = new ApolloLink(_operation => {
  return new Observable(observer => {
    observer.next({ data: null })
    observer.complete()
    return () => {}
  })
})

// terminate early and take no action when try mutation in read-only mode
const blockMutationsWhenReadOnly = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    const hasReadOnly = flagsmith.hasFeature(FeatureFlagNames.ReadOnlyMode)

    return (
      hasReadOnly &&
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'mutation'
    )
  },
  // send to noop when is read-only w/ mutation
  noopTerminatingLink,
)

const httpAndWSLink = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink,
)

const removeTypenameMiddleware = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = omitDeep(operation.variables, '__typename')
    return forward ? forward(operation) : null
  }
  return forward(operation)
})

// Attempt operation multiple times if fails due to network or server error
// https://apollographql.com/docs/react/api/link/apollo-link-retry
const retryLink = new RetryLink({
  delay: { initial: 480 }, // Starts with inital delay, then add multiples between each
  attempts: { max: 5 }, // Exponential interval *between* retries = (initial * (2 ^ max))
})

export const link = ApolloLink.from([
  contextLink,
  removeTypenameMiddleware,
  blockMutationsWhenReadOnly,
  retryLink,
  errorLink,
  httpAndWSLink,
])
