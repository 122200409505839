// NB: This is very hacky on only to get R+R over the line. We check if the
// rood+riddle 'stall' (cage) exists in our list to give a list of possible
// cages from the app. This is 100% not a good approach and will be deleted as
// soon as we support third level location options. We don't expect that any
// other organisation will have this kind of (state)->(barn) 1st->2nd level
// structure, so *getRoodRiddleThirdLevelLocations* will return null for everyone
// else. Again, please delete this file ASAP. BM: 8/10/2020

import { get } from 'lodash'

const KENTUCKY = {
  'Barn 1': [
    'Stall 1',
    'Stall 2 ',
    'Stall 3',
    'Stall 4',
    'Stall 5 ',
    'Stall 6',
    'Stall 7',
    'Stall 8 ',
    'Stall 9',
    'Stall 10',
    'Stall 11',
    'Stall 12',
    'Stall 13',
    'Stall 14',
    'Stall 15',
    'Stall 16',
    'Stall 17',
  ],
  'Barn 2': [
    'Stall 1',
    'Stall 2 ',
    'Stall 3',
    'Stall 4',
    'Stall 5 ',
    'Stall 6',
    'Stall 7',
    'Stall 8 ',
    'Stall 9',
    'Stall 10',
    'Stall 11',
    'Stall 12',
    'Stall 13',
    'Stall 14',
    'Stall 15',
    'Stall 16',
    'Stall 17',
  ],

  'Barn 3': [
    'Stall 1',
    'Stall 2 ',
    'Stall 3',
    'Stall 4',
    'Stall 5 ',
    'Stall 6',
    'Stall 7',
    'Stall 8 ',
    'Stall 9',
    'Stall 10',
    'Stall 11',
    'Stall 12',
    'Stall 13',
    'Stall 14',
    'Stall 15',
    'Stall 16',
    'Stall 17',
  ],

  'Barn 4': [
    'Stall 1',
    'Stall 2 ',
    'Stall 3',
    'Stall 4',
    'Stall 5 ',
    'Stall 6',
    'Stall 7',
    'Stall 8 ',
    'Stall 9',
    'Stall 10',
  ],

  'Barn 5': [
    'Stall 1',
    'Stall 2 ',
    'Stall 3',
    'Stall 4',
    'Stall 5 ',
    'Stall 6',
    'Stall 7',
    'Stall 8 ',
    'Stall 9',
    'Stall 10',
    'Stall 11',
    'Stall 12',
    'Stall 13',
    'Stall 14',
    'Stall 15',
    'Stall 16',
    'Stall 17',
    'Stall 18',
    'Stall 19',
    'Stall 20',
  ],
  'Barn 6': [
    'Stall 1',
    'Stall 2 ',
    'Stall 3',
    'Stall 4',
    'Stall 5 ',
    'Stall 6',
    'Stall 7',
    'Stall 8 ',
    'Stall 9',
    'Stall 10',
    'Stall 11',
    'Stall 12',
    'Stall 13',
    'Stall 14',
    'Stall 15',
    'Stall 16',
    'Stall 17',
    'Stall 18',
    'Stall 19',
    'Stall 20',
    'Stall 21',
    'Stall 22',
  ],
  'Barn 7': [
    'Stall 1',
    'Stall 2 ',
    'Stall 3',
    'Stall 4',
    'Stall 5 ',
    'Stall 6',
    'Stall 7',
  ],
  'Barn 8': [
    'Stall 1',
    'Stall 2 ',
    'Stall 3',
    'Stall 4',
    'Stall 5 ',
    'Stall 6',
    'Stall 7',
    'Stall 8 ',
    'Stall 9',
    'Stall 10',
    'Stall 11',
    'Stall 12',
    'Stall 13',
    'Stall 14',
    'Stall 15',
  ],
  'Barn 9': [
    'Stall 1',
    'Stall 2 ',
    'Stall 3',
    'Stall 4',
    'Stall 5 ',
    'Stall 6',
    'Stall 7',
    'Stall 8 ',
    'Stall 9',
    'Stall 10',
    'Stall 11',
  ],
  ICU: [
    'Stall 1',
    'Stall 2 ',
    'Stall 3',
    'Stall 4',
    'Stall 5 ',
    'Stall 6',
    'Stall 7',
    'Stall 8 ',
  ],
  OUT: ['Stall 1'],
}

const FLORIDA = {
  'Barn 1': [
    'Stall 1',
    'Stall 2 ',
    'Stall 3',
    'Stall 4',
    'Stall 5',
    'Stall 6',
    'Stall 7',
    'Stall 8',
    'Stall 9',
    'Stall 10',
    'Stall 11',
  ],
  'Barn 2': [
    'Stall 1',
    'Stall 2 ',
    'Stall 3',
    'Stall 4',
    'Stall 5 ',
    'Stall 6',
    'Stall 7',
    'Stall 8',
    'Stall 9',
  ],
  ICU: ['Stall 1', 'Stall 2 ', 'Stall 3', 'Stall 4', 'Stall 5 '],
  'Imaging Barn': ['Stall 1', 'Stall 2 ', 'Stall 3', 'Stall 4'],
  Isolation: ['Stall 1', 'Stall 2 ', 'Stall 3', 'Stall 4'],
}

const NEW_YORK = {
  'Barn 1': [
    'Radiology/Recovery',
    'Recovery',
    'Stall 1',
    'Stall 2 ',
    'Stall 3',
    'Stall 4',
    'Stall 5 ',
    'Stall 6',
    'Stall 7',
    'Stall 8 ',
    'Stall 9',
    'Stall 10',
    'Stall 11',
  ],
  'BoneScan Barn': ['Stall 1', 'Stall 2 ', 'Stall 3', 'Stall 4'],
  'Quonset Barn': [
    'Stall 1',
    'Stall 2 ',
    'Stall 3',
    'Stall 4',
    'Stall 5',
    'Stall 6',
    'Stall 7',
  ],
  Isolation: ['Stall 1', 'Stall 2 '],
  Tent: [
    'Stall 1',
    'Stall 2',
    'Stall 3',
    'Stall 4',
    'Stall 5',
    'Stall 6 ',
    'Stall 7',
    'Stall 8',
  ],
}

const locationMap = {
  Kentucky: KENTUCKY,
  Florida: FLORIDA,
  'New York': NEW_YORK,
}

export const getRoodRiddleThirdLevelLocations = (
  firstLevel?: string,
  secondLevel?: string,
) => {
  if (!firstLevel || !secondLevel) return null

  const options: string[] = get(locationMap, [firstLevel, secondLevel], [])

  if (!options.length) return null
  return options.map(option => ({ value: option, text: option }))
}
