import { gql } from '@apollo/client'

export const SETTINGS_FIELD = gql`
  fragment settingsField on Organisation {
    settings {
      key
      value
    }
  }
`

export const PERMISSIONS_FIELD = gql`
  fragment permissionsField on Organisation {
    permissions {
      name
      enabled
      role
    }
  }
`

export const ROUTE_OF_ADMINISTRATIONS_FIELD = gql`
  fragment routeOfAdministrationsField on Organisation {
    route_of_administrations {
      abbreviation
      name
      value
      default
      active
    }
  }
`

export const STAFFED_HOURS_FIELD = gql`
  fragment staffedHoursField on Organisation {
    staffed_hours {
      MON {
        enabled
        is24H
        hours {
          start
          end
        }
      }
      TUE {
        enabled
        is24H
        hours {
          start
          end
        }
      }
      WED {
        enabled
        is24H
        hours {
          start
          end
        }
      }
      THU {
        enabled
        is24H
        hours {
          start
          end
        }
      }
      FRI {
        enabled
        is24H
        hours {
          start
          end
        }
      }
      SAT {
        enabled
        is24H
        hours {
          start
          end
        }
      }
      SUN {
        enabled
        is24H
        hours {
          start
          end
        }
      }
    }
  }
`

export const GET_SETTINGS = gql`
  query getSettings($id: ID!) {
    getOrganisation(id: $id) {
      id
      name
      ...settingsField
      ...permissionsField
      ...staffedHoursField
      ...routeOfAdministrationsField
    }
  }
  ${ROUTE_OF_ADMINISTRATIONS_FIELD}
  ${SETTINGS_FIELD}
  ${PERMISSIONS_FIELD}
  ${STAFFED_HOURS_FIELD}
`

export const SET_SETTING = gql`
  mutation updateSetting($input: UpdateOrganisationInput!) {
    updateOrganisation(input: $input) {
      id
      settings {
        key
        value
      }
    }
  }
`

export const SET_STAFFED_HOURS = gql`
  mutation updateStaffedHours($input: UpdateOrganisationInput!) {
    updateOrganisation(input: $input) {
      id
      ...staffedHoursField
    }
  }
  ${STAFFED_HOURS_FIELD}
`

export const SET_ROUTE_OF_ADMINISTRATIONS = gql`
  mutation updateRouteOfAdministrations($input: UpdateOrganisationInput!) {
    updateOrganisation(input: $input) {
      id
      ...routeOfAdministrationsField
    }
  }
  ${ROUTE_OF_ADMINISTRATIONS_FIELD}
`

export const CREATE_USER = gql`
  mutation createUser($input: UserCreateInput!) {
    createUser(input: $input) {
      id
      display_name
      full_name
      has_login
      id
      job_title
      role
      veterinary_role
    }
  }
`

export const DELETE_USER = gql`
  mutation deleteUser($id: String!, $organisation_id: String!) {
    deleteUser(id: $id, organisation_id: $organisation_id)
  }
`

export const GET_USER = gql`
  query getUser($id: ID!, $organisation_id: String!) {
    getUser(id: $id, organisation_id: $organisation_id) {
      auth_provider_id
      departments
      display_name
      email
      full_name
      has_login
      id
      job_title
      phone_number
      role
      veterinary_role
      approval_role
      auto_logout_timeout
      deleted_at
      password_expired_at
      skip_change_pwd_exp
      email_verified
    }
  }
`

export const GET_USER_ORGANISATIONS = gql`
  query getUserOrganisations {
    getUserOrganisations {
      items {
        id
        name
      }
    }
  }
`

export const UPDATE_USER = gql`
  mutation updateUser($input: UserUpdateInput!, $options: UserUpdateOptions) {
    updateUser(input: $input, options: $options) {
      id
      full_name
      display_name
    }
  }
`

/*
   A light version for GET_USERS
*/
export const GET_USERS_LIST = gql`
  query getUsersList(
    $organisation_id: String!
    $veterinary_role: String
    $origin_type: OriginTypes
  ) {
    getUsersList(
      organisation_id: $organisation_id
      veterinary_role: $veterinary_role
      origin_type: $origin_type
    ) {
      items {
        id
        display_name
        full_name
        role
        sites
        veterinary_role
      }
    }
  }
`

/* fetchPolicy 'cache-and-network' seems to be the best compromise most times we
get users. Generally, users won't change and we don't want to wait for user list
updates. But, we'd like the network to eventually propagate changes to the app
cache. Because changing the user list is rare, this means the network request is
probably wasted 90%+ of the time. As an improvement, we could consider going
back to cache-first and adding a 'refresh' button to the user list. */
export const GET_USERS = gql`
  query getUsers(
    $organisation_id: String!
    $usersPageInput: PageInput
    $userOriginType: OriginTypes
    $veterinary_role: String
  ) {
    getUsers(
      organisation_id: $organisation_id
      usersPageInput: $usersPageInput
      userOriginType: $userOriginType
      veterinary_role: $veterinary_role
    ) {
      items {
        id
        display_name
        full_name
        has_login
        id
        job_title
        role
        veterinary_role
        sites
      }
    }
  }
`
export const GET_CUSTOM_PRODUCTS = gql`
  query getCustomProducts($id: ID!, $productPageInput: PageInput) {
    getOrganisation(id: $id) {
      id
      products(masterProductType: CUSTOM, productPageInput: $productPageInput) {
        items {
          id
          name
          products {
            items {
              id
              name
              type
            }
          }
          track_vital
          type
        }
      }
    }
  }
`

export const GET_SHEET_GROUPS = gql`
  query getSheetGroups($id: ID!, $productPageInput: PageInput) {
    getOrganisation(id: $id) {
      id
      products(
        masterProductType: EZYVET_SHEET_GROUPS
        productPageInput: $productPageInput
      ) {
        items {
          id
          name
        }
      }
    }
  }
`

export const MEDICATION_FIELDS = gql`
  fragment MedicationFields on Product {
    medicine_dosage_info {
      is_cri
      infusion_rate
      is_diluted
      calculate_base
      route_of_administration
      concentration
      concentration_volume_unit
      concentration_weight_unit
      diluted_concentration
      diluted_concentration_volume_unit
      diluted_concentration_weight_unit
      diluted_concentration_ratio_factor
      diluted_concentration_ratio_volume
      dosage
      dosage_weight_unit
      dosage_patient_weight_unit
      lowest_dosage
      highest_dosage
    }
  }
`

export const CUSTOM_PRODUCT_FIELDS = gql`
  fragment CustomProductFields on Product {
    description
    id
    name
    parent_product_id
    origin_id
    pims_mapping {
      dest
      field
      type
    }
    is_value_required
    is_notes_required
    track_vital
    type
    is_billable
    custom_values {
      key
      value
    }
  }
`
export const GET_CUSTOM_PIMS_MAPPING_OPTIONS = gql`
  query getCustomPimsMappingOptions($organisation_id: ID!) {
    getCustomPimsMappingOptions(organisation_id: $organisation_id) {
      value
      text
    }
  }
`

export const GET_CUSTOM_PRODUCT = gql`
  query getProduct($id: ID!, $organisation_id: ID!) {
    getProduct(id: $id, organisation_id: $organisation_id) {
      ...CustomProductFields
      ...MedicationFields
      products {
        items {
          ...CustomProductFields
          ...MedicationFields
        }
      }
    }
  }
  ${MEDICATION_FIELDS}
  ${CUSTOM_PRODUCT_FIELDS}
`

export const CREATE_CUSTOM_PRODUCT = gql`
  mutation createProduct($input: CreateProductInput!) {
    createProduct(input: $input) {
      id
    }
  }
`

export const UPDATE_CUSTOM_PRODUCT = gql`
  mutation updateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      id
      name
      type
    }
  }
`

export const DELETE_CUSTOM_PRODUCT = gql`
  mutation deleteProduct($id: ID!, $organisation_id: ID!) {
    deleteProduct(id: $id, organisation_id: $organisation_id)
  }
`

export const GET_CUSTOM_PRODUCT_CSV = gql`
  query getCustomProductCSV($id: ID!, $productPageInput: PageInput) {
    getOrganisation(id: $id) {
      id
      products(masterProductType: CUSTOM, productPageInput: $productPageInput) {
        items {
          ...MedicationFields
          id
          name
          type
          sheet_group
          is_value_required
          is_notes_required
          parent_product_id
          custom_values {
            key
            value
          }
          description
          pims_mapping {
            dest
          }
        }
      }
    }
  }
  ${MEDICATION_FIELDS}
`

export const CREATE_TREATMENT_FREQUENCIES = gql`
  mutation createTreatmentFrequency($input: TreatmentFrequencyCreateInput!) {
    createTreatmentFrequency(input: $input) {
      organisation_id
      name
      active
      type
      frequencies
    }
  }
`

export const GET_TREATMENT_FREQUENCIES = gql`
  query getTreatmentFrequencies($organisationId: ID!) {
    getTreatmentFrequencies(organisation_id: $organisationId) {
      items {
        id
        name
        organisation_id
        active
        type
        frequencies
      }
    }
  }
`

export const GET_TREATMENT_FREQUENCY = gql`
  query getTreatmentFrequency($id: ID!, $organisationId: ID!) {
    getTreatmentFrequency(id: $id, organisation_id: $organisationId) {
      id
      name
      organisation_id
      active
      type
      frequencies
    }
  }
`

export const DELETE_TREATMENT_FREQUENCY = gql`
  mutation deleteTreatmentFrequency($id: ID!, $organisationId: ID!) {
    deleteTreatmentFrequency(id: $id, organisation_id: $organisationId)
  }
`

export const UPDATE_TREATMENT_FREQUENCY = gql`
  mutation updateTreatmentFrequency($input: TreatmentFrequencyUpdateInput!) {
    updateTreatmentFrequency(input: $input) {
      id
      organisation_id
      name
      active
      type
      frequencies
    }
  }
`

export const RESET_TRAINING_ORGANISATION_DATA = gql`
  mutation resetTrainingOrganisationData($id: ID!) {
    resetTrainingOrganisationData(id: $id) {
      organisation_id
      resetStatus
      message
    }
  }
`

export const SUBSCRIBE_TRAINING_ORGANISATION_DATA_RESET = gql`
  subscription trainingOrganisationDataReset($organisationId: ID!) {
    trainingOrganisationDataReset(organisation_id: $organisationId) {
      data {
        organisation_id
        resetStatus
        message
      }
    }
  }
`

export const RUN_EZYVET_DATA_IMPORT_FROM_APP = gql`
  mutation runEzyVetDataImportTaskFromApp(
    $input: EzyVetDataImportTaskFromAppInput!
  ) {
    runEzyVetDataImportTaskFromApp(input: $input) {
      id
      organisation_id
      integration_id
      timestamp
      status
      error_info
      completed_time
      sync_by
    }
  }
`

export const SUBSCRIBE_DATA_IMPORT_FROM_APP = gql`
  subscription dataImportFromApp($organisationId: ID!) {
    dataImportFromApp(organisation_id: $organisationId) {
      data {
        id
        organisation_id
        integration_id
        timestamp
        status
        error_info
        completed_time
        sync_by
      }
    }
  }
`

export const GET_DATA_SYNC_LOGS = gql`
  query getDataSyncLogs($organisationId: ID!) {
    getDataSyncLogs(organisation_id: $organisationId) {
      items {
        id
        organisation_id
        integration_id
        timestamp
        status
        error_info
        completed_time
        sync_by
      }
    }
  }
`
