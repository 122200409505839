import React, { Dispatch, useCallback, useMemo, useState } from 'react'
import { SearchBar } from 'components/AddTreatment/SearchBar'
import { Button, TextLink } from 'components/common'
import { SelectableProductRow } from 'components/common/ProductRow/SelectableProductRow'
import { getProductIsGeneralType } from 'components/Task/utils/getProductInfo'
import { find } from 'lodash'
import { useTranslation } from 'react-i18next'
import { FlatList, StyleSheet, View, Text } from 'react-native'
import { searchInList } from 'src/utils/searchInList'
import { Fonts } from 'constants/Fonts'
import { useSheetContext } from 'src/context/sheet'
import { RadioProductRow } from 'components/common/ProductRow/RadioProductRow'
import { FixedQuantityProductRow } from 'components/common/ProductRow/FixedQuantityProductRow'
import { Checkbox } from 'src/design-system/components/CheckBox/Checkbox'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { useSeparationSites } from 'src/hooks/useSeparationSites'

type ProductGroup = {
  id: string
  name: string
  order: number
}

export type Product = {
  id: string
  name: string
  type: string
  code: string | null
  is_fixed?: boolean
  row_order?: number | null
  quantity: number | null
  group: ProductGroup
  sites?: string[] | null
}

type Props = {
  onSelect: (products: Product[]) => void
  products: Product[]
  productGroups: ProductGroup[]
  treatmentName: string
  deleteTreatment: () => void
  isSingleSelect?: boolean
  isDeleteTreatmentSelected: boolean
  setIsDeleteTreatmentSelected: Dispatch<React.SetStateAction<boolean>>
}

export const ConditionalTreatmentDeleteBtn: React.FC<{
  deleteTreatment: () => void
  displayCheckboxDeleteButton?: boolean | undefined
  isDeleteTreatmentSelected?: boolean
  setIsDeleteTreatmentSelected?: Dispatch<React.SetStateAction<boolean>>
}> = ({
  deleteTreatment,
  displayCheckboxDeleteButton,
  isDeleteTreatmentSelected,
  setIsDeleteTreatmentSelected,
}) => {
  const { t } = useTranslation()
  const { isExSmallScreen } = useBreakpoint()
  return (
    <View style={styles.deleteBtn}>
      {displayCheckboxDeleteButton ? (
        <Checkbox
          label={t('sheet:conditional.deleteTreatmentPickList')}
          a11yLabel={t('sheet:conditional.deleteTreatmentPickList')}
          checked={!!isDeleteTreatmentSelected}
          onPress={() =>
            setIsDeleteTreatmentSelected &&
            setIsDeleteTreatmentSelected(!isDeleteTreatmentSelected)
          }
          labelStyles={isExSmallScreen ? styles.mobileLabelStyles : undefined}
        />
      ) : (
        <TextLink
          text={t('sheet:conditional.delete')}
          onPress={deleteTreatment}
          fontSize={14}
        />
      )}
    </View>
  )
}
export const getIsAllGeneralProducts = (products: Product[]) => {
  return products.length > 1 && products.every(getProductIsGeneralType)
}

export const SelectProductsScreen: React.FC<Props> = React.memo(
  ({
    onSelect,
    products,
    productGroups,
    treatmentName,
    deleteTreatment,
    isSingleSelect,
    isDeleteTreatmentSelected,
    setIsDeleteTreatmentSelected,
  }) => {
    const [searchText, setSearchText] = useState('')
    const { t } = useTranslation()
    const [{ sheetAttendingDepartment }] = useSheetContext()
    const [groupId, setGroupId] = useState<string | undefined>()
    const { separationSites } = useSeparationSites([
      sheetAttendingDepartment ?? '',
    ])
    const uniqueParentSiteIds = Array.from(
      new Set(separationSites.map(site => site.parent_site_id)),
    )

    const filteredProducts = useMemo(() => {
      return searchInList(
        products.filter(product => {
          if (!groupId) {
            return true
          }
          return product.group.id === groupId
        }),
        'name',
        searchText,
      )
    }, [products, groupId, searchText])

    const validProducts = useMemo(() => {
      return !isSingleSelect
        ? products.filter(
            product =>
              !product.is_fixed &&
              (product.sites?.some(site =>
                uniqueParentSiteIds.includes(site),
              ) ||
                !product.sites),
          )
        : []
    }, [isSingleSelect, products, uniqueParentSiteIds])

    const [selectedRows, setSelectedRows] = useState<Product[]>([])

    const onChange = useCallback((text: string, id?: string) => {
      setSearchText(text)
      setGroupId(id)
    }, [])

    const keyExtractor = useCallback((item: Product) => item.id, [])

    const renderProductRow = useCallback(
      ({ item }: { item: Product }) => {
        if (isSingleSelect) {
          return (
            <RadioProductRow
              onClick={() => setSelectedRows([item])}
              product={item}
              searchWords={searchText.split(/\s+/)}
              selected={selectedRows.some(product => product.id === item.id)}
              attendingDepartment={sheetAttendingDepartment}
            />
          )
        }
        if (item.is_fixed) {
          return (
            <FixedQuantityProductRow
              product={item}
              searchWords={searchText.split(/\s+/)}
              attendingDepartment={sheetAttendingDepartment}
            />
          )
        }
        return (
          <SelectableProductRow
            a11yLabel={`Select Product ${item.name}`}
            onClick={() =>
              setSelectedRows(selected =>
                !!find(selected, item)
                  ? selected.filter(e => e.id !== item.id)
                  : [...selected, item],
              )
            }
            product={item}
            searchWords={searchText.split(/\s+/)}
            selected={selectedRows.some(product => product.id === item.id)}
            attendingDepartment={sheetAttendingDepartment}
          />
        )
      },
      [searchText, selectedRows, sheetAttendingDepartment, isSingleSelect],
    )

    const displaySelectAllBtn =
      filteredProducts.filter(product => !product.is_fixed).length > 1 &&
      !isSingleSelect

    return (
      <>
        <Text style={styles.subTitle}>{`Select from ${treatmentName}`}</Text>
        <View style={styles.container}>
          <SearchBar
            groups={productGroups}
            searchText={searchText}
            groupId={groupId}
            onChange={onChange}
            noTemplate={true}
          />
          {displaySelectAllBtn ? (
            <ToggleSelectAllButton
              products={validProducts}
              setSelectedRows={setSelectedRows}
              selectedProducts={selectedRows}
            />
          ) : null}
          <FlatList
            data={filteredProducts}
            keyExtractor={keyExtractor}
            ListFooterComponent={
              <View style={styles.activityIndicator}>
                <Button
                  a11yLabel={`Setup products button`}
                  disabled={!selectedRows.length}
                  onPress={() => onSelect(selectedRows)}
                  title={
                    selectedRows.length
                      ? t('sheet:conditional.setupNumber', {
                          count: selectedRows.length,
                        })
                      : t('sheet:conditional.setup')
                  }
                />
                <ConditionalTreatmentDeleteBtn
                  displayCheckboxDeleteButton={selectedRows.length > 0}
                  deleteTreatment={deleteTreatment}
                  isDeleteTreatmentSelected={isDeleteTreatmentSelected}
                  setIsDeleteTreatmentSelected={setIsDeleteTreatmentSelected}
                />
              </View>
            }
            renderItem={renderProductRow}
          />
        </View>
      </>
    )
  },
)

type ToggleSelectAllButtonProps = {
  products: Product[]
  selectedProducts: Product[]
  setSelectedRows: React.Dispatch<React.SetStateAction<Product[]>>
}

const ToggleSelectAllButton: React.FC<ToggleSelectAllButtonProps> = ({
  products,
  selectedProducts,
  setSelectedRows,
}) => {
  const { t } = useTranslation()

  const isNotFixedProduct = (product: Product) => !product.is_fixed
  const selectableProducts = products.filter(isNotFixedProduct)

  const allSelected = selectableProducts.every(product =>
    selectedProducts.some(selected => selected.id === product.id),
  )

  const toggleAll = () => {
    if (allSelected) {
      setSelectedRows(prevSelected =>
        prevSelected.filter(
          selected =>
            !selectableProducts.some(product => product.id === selected.id),
        ),
      )
    } else {
      setSelectedRows(prevSelected => {
        const newSelections = selectableProducts.filter(
          product => !prevSelected.some(selected => selected.id === product.id),
        )
        return [...prevSelected, ...newSelections]
      })
    }
  }

  return (
    <View style={styles.selectAll}>
      <Checkbox
        onPress={toggleAll}
        checked={allSelected}
        label={
          allSelected
            ? t('sheet:selectAll.deselectAll')
            : t('sheet:selectAll.selectAll')
        }
      />
    </View>
  )
}

const styles = StyleSheet.create({
  activityIndicator: {
    marginTop: 25,
  },
  container: {
    flex: 1,
  },
  subTitle: {
    fontFamily: Fonts.regular,
    textAlign: 'center',
    marginTop: -15,
  },
  deleteBtn: {
    width: '100%',
    alignItems: 'center',
    marginTop: 15,
  },
  mobileLabelStyles: {
    flexWrap: 'wrap',
    width: '80%',
  },
  selectAll: {
    flexDirection: 'row',
    paddingBottom: 16,
    paddingLeft: 16,
  },
})
