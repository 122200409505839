import { Auth } from 'aws-amplify'
import { Auth as VAuth, authTypeService } from 'src/context/auth'

export const getAccessToken = async () => {
  const isAuthV2 = authTypeService.getIsAuthV2()
  if (isAuthV2) {
    return (
      // edgeCase: users login via AuthV1 later with AuthV2: When AuthV2 isn't immediately ready, then keep using AuthV1
      // TODO: remove this edgeCase after we release our AuthV2 in https://idexx.atlassian.net/browse/VR-10704
      (await VAuth.currentSession())?.authenticationResult.accessToken ||
      (await Auth.currentSession())?.getAccessToken()?.getJwtToken()
    )
  }
  return (await Auth.currentSession()).getAccessToken().getJwtToken()
}
