import AsyncStorage from '@react-native-async-storage/async-storage'
import { SyncStorage } from 'src/utils/SyncStorage'
import {
  AuthFailures,
  ErrorCode,
  PwdActionInfo,
  UserSession,
  UserSessionAPIData,
} from './type'
import {
  Auth,
  AuthAPI,
  authTypeService,
  ONE_MIN,
  userOrganisationService,
} from './index'
import { AUTH_WEIGHT } from '../auth'
import { pinSwitchService } from 'src/utils/pinSwitchService'
import { SuperUserEmails } from 'constants/constants'
import { idleTimerService } from 'src/utils/idleTimerService'
import { deviceLockService } from 'src/utils/deviceLockService'
import { ddLogs } from 'src/utils/errorTracking/datadog'

const LOCAL_STORAGE_KEY = 'apc_user_session'

export const userSessionService = {
  userSession: {} as UserSession,

  async init() {
    const sessionData = SyncStorage.getItem(LOCAL_STORAGE_KEY)
    try {
      this.userSession = sessionData ? JSON.parse(sessionData) : {}
      const isAuthV2 = authTypeService.getIsAuthV2()
      const hasV2Session = this.hasV2Session()
      if (!isAuthV2 || hasV2Session) return
      // If isAuthV2 is true and not hasV2Session, try to initialize session via local refreshToken
      const keys = await AsyncStorage.getAllKeys()
      const v1AuthRegex = /users|CognitoIdentityServiceProvider/
      const v1AuthKeys = keys.filter(key => v1AuthRegex.test(key))
      ddLogs.addLoggerGlobalContext('New Auth release, v1/v2 v1AuthKeys', {
        v1AuthKeys,
      })
      const refreshTokenKey = v1AuthKeys.find(key =>
        key.includes('refreshToken'),
      )
      if (!refreshTokenKey) return
      const refreshToken = await AsyncStorage.getItem(refreshTokenKey)
      ddLogs.addLoggerGlobalContext('New Auth release, v1/v2 refreshToken', {
        refreshToken,
      })
      if (!refreshToken) return
      await Auth.refreshToken(refreshToken)
      await Auth.getUserInfo()
      const userSession = userSessionService.getUserSession(true)
      ddLogs.addLoggerGlobalContext('New Auth release, v1/v2 userSession', {
        userSession,
      })

      await AsyncStorage.multiRemove(v1AuthKeys)
      if (v1AuthKeys.length > 0) pinSwitchService.unInit()
      userSessionService.setAuthWeight(AUTH_WEIGHT.AUTHENTICATED)
    } catch (e) {
      this.userSession = {} as UserSession
    } finally {
      idleTimerService.unsetIdleAt()
      deviceLockService.removeLock()
    }

    this.persist()
  },

  setUserSession(apiData: UserSessionAPIData): void {
    const { authenticationResult, userInfo, pinInfo } = apiData

    let authFailures = this.userSession.authFailures
    if (userInfo) {
      const unlockAt = userInfo?.unlock_sign_in_at
      if (unlockAt) {
        authFailures = authFailures ?? {}
        authFailures[userInfo.email] = unlockAt
      }
    }

    this.userSession = {
      ...this.userSession,
      ...(authFailures && { authFailures }),
      ...(pinInfo && { pinInfo }),
      ...(authenticationResult && { authenticationResult }),
      ...(userInfo && { userAttributes: userInfo }),
    }

    this.persist()
  },

  setAuthWeight(authWeight: number) {
    this.userSession = {
      ...this.userSession,
      authWeight,
    }
    this.persist()
  },

  setAuthFailures(email: string) {
    const authFailures = this.userSession?.authFailures ?? ({} as AuthFailures)
    const unLockAt = new Date(new Date().getTime() + 30 * ONE_MIN).toISOString()
    this.userSession = {
      ...this.userSession,
      authFailures: {
        ...authFailures,
        [email]: unLockAt,
      },
    }
    this.persist()
  },

  setErrorCode(errorCode: ErrorCode) {
    this.userSession = {
      ...this.userSession,
      errorCode,
    }
    this.persist()
  },

  setPwdActionInfo(pwdActionInfo: PwdActionInfo) {
    this.userSession = {
      ...this.userSession,
      pwdActionInfo,
    }
    this.persist()
  },

  resetPwdActionInfo() {
    this.userSession = {
      ...this.userSession,
      pwdActionInfo: undefined,
    }
    this.persist()
  },

  resetErrorCode() {
    this.userSession = {
      ...this.userSession,
      errorCode: undefined,
    }
    this.persist()
  },

  resetAuthFailures(email: string) {
    const authFailures = this.userSession?.authFailures ?? ({} as AuthFailures)
    delete authFailures[email]
    this.userSession = {
      ...this.userSession,
      authFailures,
    }
    this.persist()
  },

  checkSignInLocked(email?: string): boolean {
    if (!email) return false
    const authFailures = this.userSession?.authFailures
    const unlockAt = authFailures?.[email]
    if (!unlockAt) return false
    const isLocked = new Date() < new Date(unlockAt)
    if (!isLocked) {
      delete authFailures[email]
      this.userSession = {
        ...this.userSession,
        authFailures,
      }
      this.persist()
    }
    return isLocked
  },

  getUserOrgIds(skipTraining = true): string[] {
    const attributes = this.userSession?.userAttributes
    if (!attributes || !Array.isArray(attributes['cognito:groups'])) return []
    return (
      attributes['cognito:groups']
        .filter(
          (group: string) => !skipTraining || !group.includes('Training_-'),
        )
        .map((group: string) => group.split('|')[0]) ?? []
    )
  },

  async checkUserCanAccessLocalOrg() {
    const organisationId = userOrganisationService.getOrganisationId()
    const allowedOrgIds = this.getUserOrgIds()
    const session = this.getUserSession()
    const isSuperUser = SuperUserEmails.includes(
      session?.userAttributes?.email || '',
    )
    // if user cannot access the current organisation, delete the organisationId
    if (
      !isSuperUser &&
      organisationId &&
      !allowedOrgIds.includes(organisationId)
    ) {
      await Auth.signOut() // TODO: remove this line once we release AuthV2
      userOrganisationService.deleteOrganisationId()
      AuthAPI.postError({ errorCode: ErrorCode.USER_CONFLICT_WITH_LOCAL_ORG })
    }
  },

  getAuthWeight(): number {
    return Number(this.userSession?.authWeight ?? 0)
  },

  getPwdActionInfo(): PwdActionInfo | undefined {
    return this.userSession?.pwdActionInfo
  },

  getUserSession(fromLocal = false): UserSession | null {
    if (fromLocal) {
      ddLogs.addLoggerGlobalContext('New Auth release, v1/v2 getUserSession', {
        userSession: this.userSession,
      })
      return this.userSession ?? null
    }
    const storedData = SyncStorage.getItem(LOCAL_STORAGE_KEY)
    if (storedData) {
      return JSON.parse(storedData)
    }
    return null
  },

  getUserAttributes() {
    return this.getUserSession()?.userAttributes
  },

  deleteUserSession(): void {
    if (SyncStorage.getItem(LOCAL_STORAGE_KEY)) {
      SyncStorage.removeItem(LOCAL_STORAGE_KEY)
      this.userSession = {} as UserSession
    }
  },

  hasV2Session(): boolean {
    return !!this.getUserSession()?.authenticationResult?.refreshToken
  },

  persist() {
    if (!this.userSession || Object.keys(this.userSession).length === 0) return
    SyncStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.userSession))
  },
}
