import { useCallback } from 'react'
import { useApolloClient } from '@apollo/client'
import { pinSwitchService } from 'src/utils/pinSwitchService'
import { useDeviceLock } from 'src/context/deviceLock/deviceLock'
import { signOut as signOutHelper } from 'src/utils/signOut'
import { Auth, authTypeService } from 'src/context/auth'
import { reloadApp } from 'src/utils/reloadApp'
import { environment } from 'src/config'

const { isWeb } = environment

// TODO: This hook will be deleted in https://idexx.atlassian.net/browse/VR-10733
export const useSignOut = () => {
  const client = useApolloClient()
  const { unlock } = useDeviceLock()

  const signOut = useCallback(async () => {
    const isOldAuthV2 = authTypeService.getIsAuthV2()
    await Promise.all([
      Auth.checkAuthVersion(),
      client.clearStore(),
      signOutHelper(),
    ])
    unlock()
    pinSwitchService.clearStorage()
    const isNewAuthV2 = authTypeService.getIsAuthV2()
    if (!isWeb && !isOldAuthV2 && isNewAuthV2) {
      return reloadApp()
    }
  }, [client, unlock])

  const resetPinAndSignOut = useCallback(
    async (email: string) => {
      pinSwitchService.resetPin(email)
      await signOut()
    },
    [signOut],
  )

  return {
    signOut,
    resetPinAndSignOut,
  }
}
